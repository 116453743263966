<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value && value.length ? value.map((item) => dayjs(item).format('ll')).join(' - ') : 'Lifetime'"
        label="Select Date Range"
        placeholder="Select Date Range"
        prepend-inner-icon="date_range"
        class="primary--text date-range-selector"
        :class="inputClass"
        style="min-width: 17em;"
        v-bind="attrs"
        v-on="on"
        :outlined="outlined"
        :single-line="!outlined"
        :dense="dense"
        hide-details
        readonly
        flat
      ></v-text-field>
    </template>

    <v-list>
      <v-list-item
        v-if="30 <= maxDateRange"
        @click="setDateRange(1)"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>
            looks_one
          </v-icon>
        </v-list-item-icon>

        <v-list-item-title>
          Last Month
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="90 <= maxDateRange"
        @click="setDateRange(3)"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>
            looks_3
          </v-icon>
        </v-list-item-icon>

        <v-list-item-title>
          Last 3 Months
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="180 <= maxDateRange"
        @click="setDateRange(6)"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>
            looks_6
          </v-icon>
        </v-list-item-icon>

        <v-list-item-title>
          Last 6 Months
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="365 <= maxDateRange"
        @click="setDateRange(12)"
      >
        <v-list-item-icon class="mr-3">
          <v-icon>
            calendar_month
          </v-icon>
        </v-list-item-icon>

        <v-list-item-title>
          Last 1 Year
        </v-list-item-title>
      </v-list-item>

      <!-- Show the date range picker -->
      <v-menu
        v-model="shouldShowDatePickerMenu"
        :close-on-content-click="false"
        :return-value.sync="modelValue"
        transition="scale-transition"
        min-width="600"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-icon class="mr-3">
              <v-icon>
                date_range
              </v-icon>
            </v-list-item-icon>

            <v-list-item-title>
              Custom Range
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="pa-0" cols="12" md="6">
                <v-date-picker
                  v-model="modelValue[0]"
                  color="primary"
                  scrollable
                  :min="min"
                  :max="max"
                />
              </v-col>

              <v-col class="pa-0" cols="12" md="6">
                <v-date-picker
                  v-model="modelValue[1]"
                  color="primary"
                  scrollable
                  :min="min"
                  :max="max"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="shouldShowDatePickerMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="updateDatePickerValue"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-list>
  </v-menu>
</template>

<script>
// Export the SFC
export default {
  // Name of this component
  name: "DateRangeSelector",

  // Define incoming data from parent component
  props: {
    // The v-model value
    value: {
      type: Array,
      required: false,
      default: null
    },

    // Whether or not to show the dense version
    dense: {
      type: Boolean,
      required: false,
      default: false
    },

    // Whether or not to show the outlines in input
    outlined: {
      type: Boolean,
      required: false,
      default: true
    },

    // Whether or not to fallback to default value
    setDefaultValue: {
      type: Boolean,
      required: false,
      default: true
    },

    // The number of months to select by default
    defaultDateRange: {
      type: Number,
      required: false,
      default: 3
    },

    // The maximum number of months to show in select
    maxDateRange: {
      type: Number,
      required: false,
      default: 365
    },

    // The classes for the input element
    inputClass: {
      type: String,
      required: false,
      default: ""
    },

    // The minimum date value
    min: {
      type: String,
      required: false,
      default: null
    },

    // The maximum date value
    max: {
      type: String,
      required: false,
      default: null
    }
  },

  // Define data variables for this component
  data: () => ({
    modelValue: [],
    shouldShowDatePickerMenu: false
  }),

  // Set a watcher to see the prop value and update local modelValue for vuetify's component
  watch: {
    value: {
      handler(newValue) {
        this.modelValue = newValue
      },

      deep: true
    }
  },

  // Options API methods object
  methods: {
    /**
     * Set the date range value from presets
     *
     * @param {Number|Null} monthsCount
     * @returns {void}
     */
    setDateRange(monthsCount) {
      // If the date range model is set to null
      let localValue = this.value

      if (localValue === null) {
        // Update it to an empty array
        localValue = []
      }

      if (monthsCount === null) {
        localValue = []
      }
      else {
        // Update it's values
        localValue[0] = dayjs().subtract(monthsCount, "month").format("YYYY-MM-DD")
        localValue[1] = dayjs().format("YYYY-MM-DD")
      }

      // Also copy this to local model
      this.modelValue = localValue

      // Emit these values
      this.$emit("input", localValue)
    },

    /**
     * Called from the "OK" CTA in the Date Range Selector Popup
     *
     * @returns {void}
     */
    updateDatePickerValue() {
      // Check if the value is not null
      if (!this.modelValue) return
      // Check if there are two values in dateRange
      if (this.modelValue.length !== 2) return

      // Sort the values correctly
      this.modelValue.sort((a, b) => this.dayjs(a).unix() - this.dayjs(b).unix())

      // Emit it to parent
      this.$emit("input", this.modelValue)

      // Now hide the popup
      this.shouldShowDatePickerMenu = false
    }
  },

  /**
   * As soon as the component is ready
   *
   * @returns {void}
   */
  created() {
    // Set the date range to past 3 months by default if the value is null
    if (this.value === null && this.setDefaultValue === true) {
      this.setDateRange(this.defaultDateRange)
    }
  }
}
</script>
